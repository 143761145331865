@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    html {
        font-family: 'Great Vibes', cursive;
        font-family: 'Raleway', sans-serif;

    }
}